@import '../../styles/base';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin: 0;

    .label {
      font: normal 15px/15px var(--font-family);
      color: $text-dark-color;
    }

    .desc {
      font: normal 13px/13px var(--font-family);
      color: $text-light-color;
    }
  }

  .end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}

.toggle-container {
  margin: 0;
}
