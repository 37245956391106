@import '../../styles/base';

.aside {
  background: $black-berry;
  width: $sidebar-width;
  flex: 0 0 $sidebar-width;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  z-index: 1;

  > * {
    &:first-child {
      padding: 12px 24px;
    }
  }

  nav {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .top {
      > a {
        @extend %sidebar-item;
      }

      a,
      button {
        padding: 0 24px;
      }
    }

    .bot {
      .block {
        position: relative;
        border-top: 1px solid $border-light-color;
      }

      .block > a {
        @extend %sidebar-item;
        padding: 16px 24px;
        height: auto;
      }
    }

    button {
      &.profile {
        @extend %sidebar-item;
        margin-top: auto;
        padding: 25px 24px;
        height: 72px;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.logo {
  margin: 0 auto 40px;
}
