@import '../../styles/base';

.options {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: stretch;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  border: 1px solid $lines;
  border-radius: 8px;
  padding: 16px;
  min-width: 124px;

  h3 {
    padding: 9px;
    text-align: center;

    font: bold 13px/13px var(--font-family);
    color: $text-light-color;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background-color: $lines;
  }

  .pm-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .fee-box {
    display: block;
    border-radius: 6px;
    width: 100%;

    .fee {
      text-align: center;
      font: bold 15px/15px var(--font-family);
      white-space: nowrap;
    }
  }
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  gap: 8px;
  align-items: center;

  .add {
    margin-top: 20px;
  }

  &.right {
    justify-content: flex-end;
  }
}

.symbol {
  font-size: 11px;
  font-weight: normal;
}

.plus {
  padding: 0 2px;
  font-size: 12px;
  color: $very-light-blue;
}

.na {
  font-size: 11px;
  font-weight: normal;
}

.toggler-container {
  margin: 0;
}
