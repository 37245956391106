@import '../../styles/base';

.top-bar {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 24px;

  > h1 {
    font: 600 28px/36px $font-family;
  }

  > *:last-child {
    margin-left: auto;
    width: auto;
    padding: 0px 18px;
  }
}

.table {
  @extend %table;
  min-width: 960px;

  thead {
    tr {
      th {
        text-align: left;

        &.amount {
          // text-align: right;

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
          }
        }
      }
    }
  }

  td.manager {
    text-align: center;
    width: 48px;
  }
}

.filters {
  display: flex;
  margin: 8px 0;

  > i {
    font: 600 14px/12px $font-family;
    text-transform: uppercase;
    color: $dark-gray;
    text-decoration: none;
    padding: 10px 0;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 36px;
    }

    &:hover,
    &.active {
      color: #4456dd;
    }
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  min-height: 120px;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font: normal 13px/18px $font-family;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 24px;
  }
}
