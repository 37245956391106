@use 'sass:math';
@import '../../styles/base';

.profile-popup-container {
  position: absolute;
  top: 50%;
  left: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-8px, -67%);
  transform: translate(-8px, calc(-50% - 18px));

  .content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 16px 0;
    box-shadow: 0 4px 16px $shadow-color;
    border-radius: 6px;

    > * {
      color: $dark-gray;
      text-decoration: none;
    }

    > *:first-child {
      @extend %border-bottom;
      font: 600 16px/28px $font-family;
      margin-bottom: 8px;
      padding: 4px 24px 12px 24px;
    }

    > *:not(:first-child) {
      font: 600 14px/18px $font-family;
      cursor: pointer;
      padding: 4px 24px;

      &:hover {
        color: $link-color;
        background-color: $super-light-gray;
      }
    }
  }

  .pop-from {
    position: absolute;
    left: -15px;
    bottom: 24px;
  }
}
