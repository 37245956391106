@import '../../styles/base';

.nav {
  display: flex;
  align-items: center;
  gap: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid $lines;
  height: 48px;

  a {
    text-decoration: none;
    color: $text-light;

    &.active {
      color: $link-color;
    }
  }

  .right {
    margin-left: auto;
  }
}
