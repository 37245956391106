@import '../../styles/base';

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(35, 28, 69, 0.8);
  backdrop-filter: blur(8px);

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: $white;
    border-radius: 6px;

    > form {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    h3 {
      font: 500 17px/30px $font-family;
      color: $text-dark-color;
      margin-bottom: 8px;
    }

    p {
      font: normal 13px/22px $font-family;
      color: $text-light-color;
    }

    .username {
      display: flex;
      width: 100%;
      padding: 4px 8px;
      align-items: center;
      justify-content: center;
      font: bold 17px/28px $font-family;
      color: $text-dark-color;
      background-color: $tag-background;
    }
  }

  .single {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 420px;
  }

  .cols {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 24px 0;

    &.compact {
      padding: 0;

      .col :global(.header) {
        margin: 0;
      }
    }

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 40px;

      :global(.dialog) & {
        width: 390px;
      }

      .compact &,
      &.compact {
        gap: 24px;
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

  > * {
    flex: 1;
  }
}

.footer {
  justify-content: flex-end;

  > button {
    flex-grow: 0;
    flex-basis: fit-content;
    width: auto;
  }
}
