@import './typography';

%center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

%bg-icon-info {
  background: $purple url('../assets/icon-info.svg') 16px center no-repeat;
  background-size: 16px;
}

%btn {
  text-decoration: none;
  background: $purple;
  color: #fff;
  border-radius: 6px;
  outline: 0;
  border: 0;
  padding: 0 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 48px;

  &.processing {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.primary {
    &:hover {
      border-color: $dark-purple;
      background-color: $dark-purple;
    }

    &:disabled {
      color: $text-light-color;
      background-color: $light-gray;
    }
  }

  &.outline {
    border: 1px solid $link-color;
    color: $link-color;
    background-color: transparent;

    &:hover {
      color: $dark-purple;
      border-color: $dark-purple;
    }

    &:disabled {
      border-color: $light-gray;
      color: $disable-color;
    }
  }

  &:disabled {
    cursor: default;
  }

  &.large {
    font: 600 16px/16px $font-family;
    height: 48px;
  }

  &.small {
    font: 600 14px/16px $font-family;
    height: 32px;
  }

  .processing {
    display: inline-block;
    animation: infinite-rotate 1s linear infinite;

    &.large {
      width: 24px;
      height: 24px;
    }

    &.small {
      width: 20px;
      height: 20px;
    }
  }
}

%sidebar-item {
  display: block;
  position: relative;
  font: 600 14px/24px $font-family;
  color: #f1f3ff;
  text-decoration: none;
  display: flex;
  height: 40px;
  align-items: center;

  img {
    margin-right: 12px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    opacity: 0.5;
  }

  span {
    opacity: 0.5;
  }

  &.active {
    > span {
      opacity: 1;
    }

    > img {
      opacity: 1;
    }
  }
}

%message {
  display: block;
  padding: 0 16px;
  font-size: 13px;
  line-height: 32px;
  color: $link-color;
  border-radius: 4px;

  &.info {
    background: #e7f0ff url('../assets/icon-info-purple.svg') 16px center
      no-repeat;
    background-size: 16px;
    padding-left: 40px;
  }
}

%wrapper {
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}

%table {
  width: 100%;
  min-width: 960px;

  thead {
    tr {
      border-bottom: 1px solid #f1f2ff;
      border-top: 1px solid #f1f2ff;
    }

    th {
      padding: 0 24px;
      font: 600 12px/16px $font-family-ja;
      text-transform: uppercase;
      height: 32px;
      vertical-align: middle;
      white-space: nowrap;
      background-color: $super-light-gray;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #f1f2ff;

      td {
        white-space: nowrap;
        height: 42px;
        vertical-align: middle;
      }
    }
  }
}

%setting-layout {
  main {
    width: 1264px;
    margin: 0 auto;
  }
}

%setting-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 800px;
  padding: 4px 0px;

  > :first-child {
    margin-right: 24px;
  }
}

%section {
  width: calc(50% - 12px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > h2 {
    font: 600 16px/28px $font-family;
    margin-top: 24px;
    margin-bottom: 16px;
    padding-bottom: 16px;

    > i {
      font: 400 15px/28px $font-family;
      color: $text-light-color;
      margin-left: 14px;
    }
  }

  > * {
    width: 100%;

    &.col-2 {
      width: calc((100% - 16px) / 2);
    }
  }

  input {
    font-weight: bold;
  }
}

%rounded-rectangle {
  border-radius: 4px;
  color: $dark-gray;
  background-color: $super-light-gray;
  padding: 0 8px;
  display: inline-block;
}

%period {
  @extend %rounded-rectangle;
  display: inline-block;
  font: 600 11px/24px $font-family;
  text-transform: uppercase;
  text-align: center;
  width: 128px;
}

%border-top {
  border-top: 1px solid $border-color;
}

%border-bottom {
  border-bottom: 1px solid $border-color;
}

%border {
  border: 1px solid #dfe1e5;
  transition: border-color 0.15s ease-in-out;
  border-radius: 6px;
}

@keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
