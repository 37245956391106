@import '../../styles/base';

.header {
  position: fixed;
  z-index: 20000;
  height: 48px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 16px;
  color: #fff;
  background: $black-berry;

  h1 {
    font: 500 16px/32px $font-family;
  }

  .id {
    display: block;
    border: 1px solid #8a87a2;
    border-radius: 4px;
    padding: 0 16px;
    color: #8a87a2;
    font: normal 12px/22px $font-family;
  }

  .right {
    display: flex;
    gap: 12px;
    margin-left: auto;
    color: #8a87a2;

    > div {
      position: relative;
      display: flex;
    }
  }

  .settings {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    font: 700 14px/30px $font-family;
    color: #8a87a2;
  }

  .lang-switcher {
    border: 1px solid #8a87a2;
    border-radius: 6px;
    color: #f1f3ff;
    cursor: pointer;
    font: 700 14px/30px $font-family;
    padding: 0 16px;
    text-decoration: none;

    &:focus {
      outline: 0;
    }

    &:hover {
      border-color: #fff;
      color: #fff;
      opacity: 1;
    }
  }
}
