@import '../../styles/base';

tr.user {
  td {
    font: 400 13px/22px $font-family;
    color: $dark-gray;
    padding: 0 24px;
  }

  > td {
    &:last-child {
      width: 48px;
      white-space: nowrap;

      button:focus {
        outline: none;
      }
    }
  }

  .roles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 8px;
    padding: 4px 0;
  }

  .role {
    display: block;
    border: 1px solid $lines;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 10px;
    line-height: 20px;
    color: $text-light;
    text-transform: uppercase;

    &.CorporateOwner,
    &.CorporateManager,
    &.Manager {
      border-color: $ghost;
      background-color: $ghost;
    }

    &.OrdersViewer,
    &.OrdersWithPersonalInfoViewer,
    &.RefundsViewer,
    &.CouponsViewer,
    &.PayLinksViewer,
    &.PayoutsViewer,
    &.SettingsViewer {
      padding-right: 30px;
      background: url('../../assets/icon-viewer.svg') right 8px center no-repeat;
      background-size: 16px 16px;
    }

    &.OrdersEditor,
    &.OrdersWithPersonalInfoEditor,
    &.RefundsEditor,
    &.CouponsEditor,
    &.PayLinksEditor,
    &.PayoutsEditor,
    &.SettingsEditor {
      padding-right: 30px;
      background: url('../../assets/icon-editor.svg') right 8px center no-repeat;
      background-size: 16px 16px;
    }
  }
}
