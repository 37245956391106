@import './colors';

$font-family-ja: Hiragino Sans, Hiragino Kaku Gothic ProN, BIZ UDPGothic,
  Noto Sans JP, Meiryo UI;
$font-family: -apple-system, BlinkMacSystemFont, $font-family-ja, sans-serif;
$font-family-mono: 'SF Mono', monospace;

$font-weight: (
  thin: 100,
  extra-light: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900,
);

$base-font-size: 16px;
$min-device-width: 320px;
$arrow-height: 16px;
$arrow-width: 24px;
$sidebar-width: 184px;
$corporate-sidebar-width: 60px;
$ribbon-height: 29px;
$main-padding: 92px;
