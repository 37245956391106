@import '../../styles/base';

.layout {
  @extend %setting-layout;
}

.container {
  @extend %setting-container;

  .toggler-container {
    margin: 0;
  }

  > section {
    > h2 {
      font: 600 16px/28px $font-family;
      margin-top: 24px;
      margin-bottom: 16px;
      padding-bottom: 16px;

      > i {
        font: 400 15px/28px $font-family;
        color: $text-light-color;
        margin-left: 14px;
      }
    }
  }

  .main {
    margin: 0 auto;
    width: fit-content;
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 8px auto;
    border: 1px solid $lines;
    border-radius: 16px;
    padding: 16px;
    width: fit-content;

    > h2 {
      border-bottom: 1px solid $lines;
      padding-bottom: 16px;
      text-align: center;
      font: 600 16px/28px var(--font-family);
    }

    .options {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: stretch;
    }

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 9px;
      border: 1px solid $lines;
      border-radius: 8px;
      padding: 16px;
      width: 154px;

      h3 {
        padding: 9px;
        text-align: center;

        font: bold 13px/13px var(--font-family);
        color: $text-terciary;
      }

      hr {
        border: 0;
        width: 100%;
        height: 1px;
        background-color: $lines;
      }

      .pm-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .fee-box {
        display: block;
        border-radius: 6px;
        padding: 11px;
        width: 100%;

        background-color: $background-gray;

        h4 {
          margin-bottom: 8px;
          text-align: center;
          font: normal 10px/10px var(--font-family);
        }

        .fee {
          text-align: center;
          font: normal 18px/18px var(--font-family);
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      font: normal 14px/16px var(--font-family);
      color: $blackberry;
    }

    .no-fee {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      font: bold 14px/16px $font-family;
    }

    .action {
      width: auto;
      padding: 8px 24px;
    }
  }

  .note {
    font: normal 15px/28px var(--font-family);
    color: $text-medium;
  }
}

.disabled {
  opacity: 0.2;
}

.disabled .disabled {
  opacity: 1;
}

.symbol {
  font-size: 11px;
  font-weight: normal;
}

.plus {
  padding: 0 2px;
  font-size: 12px;
  color: $very-light-blue;
}

.na {
  font-size: 11px;
  font-weight: normal;
}
